import React, { Fragment, useMemo } from 'react';
import {
  Card,
  CardTitle,
  CardSubtitle,
  CardBody,
  UncontrolledTooltip,
} from 'reactstrap';
import { FaArrowTrendUp, FaCode, FaHammer } from 'react-icons/fa6';
import { RiTeamFill } from 'react-icons/ri';
import { Avatar } from 'components/layout/avatar';
import { MiniPeopleList } from 'components/layout/mini-people-list';
import { MentorshipPerson } from 'types';
import {
  getRoleDisplayName,
  isMenteeRole,
  isNewGradOffer,
  isNonFounderRole,
  isOrganizerRole,
  isReturnOfferForInternship,
  isReturnOfferForNewGrad,
} from 'utils/data';
import { sortBy } from 'lodash';
import { useGate } from 'statsig-react';

interface PeopleCardProps extends React.HTMLAttributes<HTMLDivElement> {
  year: number;
  person: MentorshipPerson;
}

export const PeopleCard: React.FC<PeopleCardProps> = ({ year, person }) => {
  const { name, alias, hobbies, avatar, term } = person;
  const firstTerm = useMemo(() => term[0], [term]);
  const isOrWasMentee = useMemo(
    () => isMenteeRole(firstTerm.roles),
    [firstTerm]
  );
  const currentTerm = useMemo(
    () => term.filter((t) => t.year === year)[0],
    [term, year]
  );
  const { roles, title, mentors, projectAdvisors, teamName, teammates } =
    currentTerm;
  const offers = useMemo(
    () =>
      currentTerm.offers?.map((o) => {
        if (isReturnOfferForNewGrad(o)) {
          return `${o.name} (NG RO)`;
        } else if (isReturnOfferForInternship(o)) {
          return `${o.name} (IN RO)`;
        } else if (isNewGradOffer(o)) {
          return `${o.name} (NG)`;
        }
        return o.name;
      }),
    [currentTerm]
  );

  const { value: showIndividualOffers, isLoading } = useGate(
    'show_individual_offers'
  );

  return (
    <Card className="mentorship-people-card">
      <div className="info-assignment">
        {mentors && (
          <CardSubtitle className="info-mentors app-flex af-right mt-1 mb-2">
            <FaArrowTrendUp id={`${alias}-mentors-icon`} />
            <UncontrolledTooltip
              placement="bottom"
              target={`${alias}-mentors-icon`}
            >
              One-on-one mentors
            </UncontrolledTooltip>
            <MiniPeopleList peopleList={mentors} prefix={alias} />
          </CardSubtitle>
        )}

        {projectAdvisors && (
          <CardSubtitle className="info-advisors app-flex af-right mt-2">
            <FaCode id={`${alias}-advisors-icon`} />
            <UncontrolledTooltip
              placement="bottom"
              target={`${alias}-advisors-icon`}
            >
              Project technical mentors
            </UncontrolledTooltip>
            <MiniPeopleList peopleList={projectAdvisors} prefix={alias} />
          </CardSubtitle>
        )}
      </div>
      <Avatar url={avatar} />
      <CardBody>
        <CardTitle>{name}</CardTitle>
        {isOrganizerRole(roles) && (
          <CardSubtitle>
            {roles.filter(isNonFounderRole).map(getRoleDisplayName).join(' / ')}
          </CardSubtitle>
        )}
        {teammates && (
          <div className="app-flex mt-2">
            <FaHammer id={`${alias}-build-icon`} /> {teamName}
            <UncontrolledTooltip
              placement="bottom"
              target={`${alias}-build-icon`}
            >
              Group project
            </UncontrolledTooltip>
            <RiTeamFill id={`${alias}-teammates-icon`} />
            <UncontrolledTooltip
              placement="bottom"
              target={`${alias}-teammates-icon`}
            >
              Teammates
            </UncontrolledTooltip>
            <MiniPeopleList peopleList={teammates} prefix={alias} />
          </div>
        )}
        <hr />
        <CardSubtitle className="people-title">{title}</CardSubtitle>
        {/* <CardSubtitle>{hobbies}</CardSubtitle> */}
        {!isLoading && showIndividualOffers && offers && isOrWasMentee && (
          <Fragment>
            <hr />
            <CardSubtitle className="people-offers">
              Offers: {sortBy(offers).join(', ')}
            </CardSubtitle>
          </Fragment>
        )}
      </CardBody>
    </Card>
  );
};
