import React, { useState } from 'react';
import {
  Collapse,
  Navbar as BootstrapNavbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <BootstrapNavbar expand="md" container>
      <NavbarBrand href="/">
        <img
          alt="Viet Tech logo"
          src="https://res.cloudinary.com/khoa165/image/upload/v1718192551/viettech/VTMP_logo.png"
          style={{
            height: 40,
            width: 40,
            marginRight: 15,
          }}
        />
        Viet Tech Mentorship Program
      </NavbarBrand>
      <NavbarToggler onClick={toggle} color="white" />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="me-auto" navbar></Nav>
        <Nav className="ms-auto" navbar>
          <NavItem>
            <NavLink href="/">Home</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/people">People</NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink href="/projects">Projects</NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink href="/stats">Stats</NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </BootstrapNavbar>
  );
};
