import { min } from 'lodash';
import React, { Fragment } from 'react';
import {
  BarChart,
  Bar,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import { MergedInterviewData } from 'types';
import { MentorshipYear, yearDisplay } from 'utils/constants';

interface InterviewsBarChartProps {
  data: MergedInterviewData;
}
export const InterviewsBarChart: React.FC<InterviewsBarChartProps> = ({
  data,
}) => {
  return (
    <div>
      <h2 className="chart-title">
        [2023 + 2024] {data.totalInvitationsCount} invitations and{' '}
        {data.totalInterviewsCount} interviews
      </h2>
      <BarChart
        width={min([1000, window.innerWidth - 10])}
        height={4000}
        data={data.data}
        className="mt-4"
        layout="vertical"
        barSize={10}
      >
        <CartesianGrid strokeDasharray="1 15" horizontal={false} />
        <XAxis type="number" tick={{ fill: '#7aa6c2' }} />
        <YAxis
          dataKey="company"
          type="category"
          textAnchor="end"
          width={125}
          tick={{ fill: '#7aa6c2' }}
        />
        <Tooltip wrapperClassName="tooltip-box" />
        {[MentorshipYear.YEAR_2023, MentorshipYear.YEAR_2024].map((year) => (
          <Fragment>
            <Bar
              key={year}
              dataKey={(d) => d[year]?.mixedCount ?? 0}
              stackId={year}
              fill="#d8aa96"
              name="Mixed"
            />
            <Bar
              dataKey={(d) => d[year]?.technicalCount ?? 0}
              stackId={year}
              fill="#c7d3bf"
              name="Technical"
            />
            <Bar
              dataKey={(d) => d[year]?.behavioralCount ?? 0}
              stackId={year}
              fill="#807182"
              name="Behavioral"
            />
            <Bar
              dataKey={(d) => d[year]?.practicalCount ?? 0}
              stackId={year}
              fill="#f7b1ab"
              name="Practical"
            />
          </Fragment>
        ))}
        <Legend
          iconType="circle"
          verticalAlign="top"
          content={(props) => renderLegend({ ...props, data })}
        />
      </BarChart>
    </div>
  );
};

const renderLegend = (props) => {
  const { payload, data } = props;
  console.log(data);
  const colors = ['#d8aa96', '#c7d3bf', '#807182', '#f7b1ab'];
  const funcs = [
    (year) => data[year]?.totalMixedCount ?? 0,
    (year) => data[year]?.totalTechnicalCount ?? 0,
    (year) => data[year]?.totalBehavioralCount ?? 0,
    (year) => data[year]?.totalPracticalCount ?? 0,
  ];

  return (
    <div className="interviews-legend mb-4">
      <div className="d-flex justify-content-center">
        <span className="me-5">Mentorship program 2023</span>
        {payload.slice(0, 4).map((entry, index) => (
          <span
            key={`item-${index}`}
            className="mx-3"
            style={{ color: colors[index % 4] }}
          >
            {funcs[index](MentorshipYear.YEAR_2023)} {data.length} {entry.value}
          </span>
        ))}
      </div>
      <hr className="my-2" />
      <div className="d-flex justify-content-center">
        <span className="me-5">Mentorship program 2024</span>
        {payload.slice(4, 8).map((entry, index) => (
          <span
            key={`item-${index}`}
            className="mx-3"
            style={{ color: colors[index % 4] }}
          >
            {funcs[index](MentorshipYear.YEAR_2024)} {entry.value}
          </span>
        ))}
      </div>
    </div>
  );
};
