import M120230430DeveloperProductivity from "../content/M1-2023-04-30-developer-productivity.md";
import M120230430ToolsForTeam from "../content/M1-2023-04-30-tools-for-team.md";
import M120230430UsingGit from "../content/M1-2023-04-30-using-git.md";
import M120230730MentorshipHalfWayReflection from "../content/M1-2023-07-30-mentorship-half-way-reflection.md";
import M120230815Team12023 from "../content/M1-2023-08-15-team1-2023.md";
import M120230815Team22023 from "../content/M1-2023-08-15-team2-2023.md";
import M120230815Team32023 from "../content/M1-2023-08-15-team3-2023.md";
import M120231215Summary2023 from "../content/M1-2023-12-15-summary-2023.md";
import M220240322TechnicalWorkshops2024 from "../content/M2-2024-03-22-technical-workshops-2024.md";

const allFiles = [
  M220240322TechnicalWorkshops2024,
  M120231215Summary2023,
  M120230815Team12023,
  M120230815Team22023,
  M120230815Team32023,
  M120230730MentorshipHalfWayReflection,
  M120230430DeveloperProductivity,
  M120230430ToolsForTeam,
  M120230430UsingGit
];

export default allFiles;
