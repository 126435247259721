import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import 'styles/scss/people.scss';
import { getRoleDisplayName } from 'utils/data';
import { MentorshipRole, MentorshipYear, yearDisplay } from 'utils/constants';
import { PeopleGrid } from './people-grid';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useMentorshipPeople } from 'hooks/useMentorshipPeople';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface PeoplePageProps {
  year: number;
}

export const PeoplePage: React.FC<PeoplePageProps> = ({ year }) => {
  const navigate = useNavigate();

  const defaultRoles = [
    MentorshipRole.PROGRAM_ADVISOR,
    MentorshipRole.PROGRAM_LEAD,
    MentorshipRole.LEAD,
    MentorshipRole.MENTOR,
    MentorshipRole.MENTEE,
  ];

  const { people, setSelectedRoles } = useMentorshipPeople(year);

  const totalOffers = useMemo(
    () =>
      people.reduce((p, c) => {
        const currentTerm = c.term.find((t) => t.year === year);
        return p + (currentTerm?.offers?.length ?? 0);
      }, 0),
    [people, year]
  );

  const onChangeYear = (_event, value) => {
    navigate(`/people/${value}`);
  };

  const onChangeGroup = (_event, value) => {
    setSelectedRoles(value);
  };

  const mentorshipYears = useMemo(
    () => Object.keys(MentorshipYear).map((y) => yearDisplay[y] as number),
    []
  );

  return (
    <div id="people-page">
      <div className="app-flex">
        <Autocomplete
          className="vtmp-autocomplete year-autocomplete"
          options={mentorshipYears}
          getOptionLabel={(option) => option.toString()}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Year" />}
          defaultValue={year}
          onChange={onChangeYear}
          disableClearable
        />
        <Autocomplete
          className="vtmp-autocomplete role-autocomplete"
          multiple
          options={Object.values(MentorshipRole).filter(
            (r) => !r.startsWith('PROGRAM_CF')
          )}
          getOptionLabel={(option) =>
            getRoleDisplayName(option as MentorshipRole)
          }
          sx={{ width: 800 }}
          renderInput={(params) => <TextField {...params} label="Role" />}
          renderOption={(props, option, { selected }) => {
            const { key, ...optionProps } = props;
            return (
              <li key={key} {...optionProps}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {getRoleDisplayName(option as MentorshipRole)}
              </li>
            );
          }}
          defaultValue={defaultRoles}
          onChange={onChangeGroup}
        />
      </div>
      {totalOffers >= 10 && (
        <h3 className="achievement-text mt-3 text-center text-green">
          {year} cohort achievements so far: {totalOffers} offers
        </h3>
      )}
      <PeopleGrid people={people} year={year} />
    </div>
  );
};
