import { Navigate, Route, Routes } from 'react-router-dom';
import { StatsPage } from './stats-page';
import { StatsType } from 'utils/constants';

export const StatsContainer = () => {
  return (
    <Routes>
      {[StatsType.TIMELINE, StatsType.OFFERS, StatsType.INTERVIEWS].map(
        (type) => (
          <Route
            key={type}
            path={`/${type}`}
            element={<StatsPage type={type} />}
          />
        )
      )}
      <Route
        path="*"
        element={<Navigate to={`/stats/${StatsType.TIMELINE}`} replace />}
      />
    </Routes>
  );
};
