export enum CompanyName {
  SERVICE_NOW = 'ServiceNow',
  LILLY = 'Lilly',
  MICROSOFT = 'Microsoft',
  MONGODB = 'MongoDB',
  BANK_OF_AMERICA = 'Bank of America',
  META = 'Meta',
  NIANTIC = 'Niantic',
  ROBINHOOD = 'Robinhood',
  DOORDASH = 'DoorDash',
  LINKEDIN = 'LinkedIn',
  PROOFPOINT = 'Proofpoint',
  ADOBE = 'Adobe',
  AMAZON = 'Amazon',
  PLAID = 'Plaid',
  WAYFAIR = 'Wayfair',
  GODADDY = 'GoDaddy',
  MATSON_MONEY = 'Matson Money',
  SAP = 'SAP',
  HEROKU = 'Heroku',
  MORGAN_STANLEY = 'Morgan Stanley',
  SHOPIFY = 'Shopify',
  MASTERCARD = 'Mastercard',
  SLACK = 'Slack',
  LIBERTY_MUTUAL_INSURANCE = 'Liberty Mutual Insurance',
  HELMERICH_PAYNE = 'Helmerich & Payne',
  GRAMMARLY = 'Grammarly',
  BNY_MELLON = 'BNY Mellon',
  PAYPAL = 'PayPal',
  GOLDMAN_SACHS = 'Goldman Sachs',
  EBAY = 'eBay',
  VERISK = 'Verisk',
  HUBSPOT = 'HubSpot',
  EXPEDIA = 'Expedia',
  ORACLE = 'Oracle',
  EXPERIAN = 'Experian',
  WAYMO = 'Waymo',
  ASANA = 'Asana',
}

export enum OfferType {
  INTERNSHIP = 'INTERNSHIP',
  NEW_GRAD = 'NEW_GRAD',
}

export enum OfferChannel {
  EXTERNAL = 'EXTERNAL',
  RETURN_OFFER = 'RETURN_OFFER',
}

export enum MentorshipRole {
  PROGRAM_CF1 = 'PROGRAM_CF1',
  PROGRAM_CF2 = 'PROGRAM_CF2',
  PROGRAM_ADVISOR = 'PROGRAM_ADVISOR',
  PROGRAM_LEAD = 'PROGRAM_LEAD',
  LEAD = 'LEAD',
  MENTOR = 'MENTOR',
  MENTEE = 'MENTEE',
}

export enum MentorshipGroup {
  ORGANIZER = 'ORGANIZER',
  PARTICIPANT = 'PARTICIPANT',
}

export const roleDisplayName: {
  [key in MentorshipRole]: string;
} = {
  [MentorshipRole.PROGRAM_CF1]: 'Program Founder',
  [MentorshipRole.PROGRAM_CF2]: 'Program Founder',
  [MentorshipRole.PROGRAM_LEAD]: 'Program Lead',
  [MentorshipRole.PROGRAM_ADVISOR]: 'Program Advisor',
  [MentorshipRole.LEAD]: 'Lead',
  [MentorshipRole.MENTOR]: 'Mentor',
  [MentorshipRole.MENTEE]: 'Mentee',
};

export const rolePriority: {
  [key in MentorshipRole]: number;
} = {
  [MentorshipRole.PROGRAM_LEAD]: 1,
  [MentorshipRole.PROGRAM_CF1]: 1.1,
  [MentorshipRole.PROGRAM_CF2]: 1.2,
  [MentorshipRole.PROGRAM_ADVISOR]: 2,
  [MentorshipRole.LEAD]: 3,
  [MentorshipRole.MENTOR]: 4,
  [MentorshipRole.MENTEE]: 5,
};

export const groupDisplayName: {
  [key in MentorshipGroup]: string;
} = {
  [MentorshipGroup.ORGANIZER]: 'Organizers',
  [MentorshipGroup.PARTICIPANT]: 'Participants',
};

export enum MentorshipYear {
  YEAR_2023 = 'YEAR_2023',
  YEAR_2024 = 'YEAR_2024',
  YEAR_2025 = 'YEAR_2025',
}

export const yearDisplay: {
  [key in MentorshipYear]: number;
} = {
  [MentorshipYear.YEAR_2023]: 2023,
  [MentorshipYear.YEAR_2024]: 2024,
  [MentorshipYear.YEAR_2025]: 2025,
};

export enum StatsType {
  OFFERS = 'offers',
  INTERVIEWS = 'interviews',
  TIMELINE = 'timeline',
}

export enum InterviewType {
  TECHNICAL_LC_CODING = 'T',
  OVERALL_BEHAVIORAL = 'B',
  PROJECT_WALKTHROUGH = 'W',
  SYSTEM_DESIGN = 'S',
  RECRUITER_SCREEN = 'R',
  HIRING_MANAGER = 'H',
  TRIVIA_CONCEPT = 'V',
  PRACTICAL_CODING = 'P',
  DEBUGGING = 'D',
  CRITICAL_THINKING = 'C',
  CODE_REVIEW = 'O',
}
