import React, { useEffect } from 'react';
import AOS from 'aos';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navbar } from 'components/layout/navigation-bar';
import { PeopleContainer } from 'components/people';
import { StatsContainer } from 'components/stats';
import { ProjectsContainer } from 'components/projects';
import { TreeContainer } from 'components/tree';
import 'styles/scss/app.scss';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div id="mentorship-website">
        <Router>
          <Navbar />
          <Routes>
            <Route path="/people/*" element={<PeopleContainer />} />
            <Route path="/projects" element={<ProjectsContainer />} />
            <Route path="/stats/*" element={<StatsContainer />} />
            <Route path="/*" element={<TreeContainer />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
};
