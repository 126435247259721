import { useMemo, useState } from 'react';
import {
  doesPersonHaveAtLeastOneRoleInYear,
  getPersonPriorityInYear,
} from 'utils/data';
import { MentorshipRole } from 'utils/constants';
import { mentorshipPeople } from 'data/people';
import { sortBy } from 'lodash';

export const useMentorshipPeople = (year: number) => {
  const [selectedRoles, setSelectedRoles] = useState<MentorshipRole[]>([]);
  const roles = useMemo(() => {
    if (selectedRoles.length === 0) {
      const filteredRoles = [
        MentorshipRole.PROGRAM_ADVISOR,
        MentorshipRole.PROGRAM_LEAD,
        MentorshipRole.LEAD,
        MentorshipRole.MENTOR,
        MentorshipRole.MENTEE,
      ];
      return filteredRoles;
    }
    return selectedRoles;
  }, [selectedRoles]);

  const people = useMemo(() => {
    const filteredPeople = Object.values(mentorshipPeople).filter((p) =>
      doesPersonHaveAtLeastOneRoleInYear(p, roles, year)
    );
    return sortBy(
      filteredPeople,
      [(p) => getPersonPriorityInYear(p, year)],
      (p) => p.name
    );
  }, [roles, year]);

  return { people, setSelectedRoles };
};
